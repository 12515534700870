



























































































































































































































import { Renter } from '@/api/interfaces/renter'
import { newRenter } from '@/components/molecules/select-search/store/renter'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  components: {
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    renterEdit: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      isEditingRenter: false,
      renter: newRenter() as Renter,
    }
  },
  watch: {
    renterEdit: {
      handler (newVal) {
        if (newVal && newVal['@id']) {
          (this.renter as Renter) = this.renterEdit as Renter
          this.isEditingRenter = true
        } else {
          this.renter = newRenter()
          this.isEditingRenter = false
        }
      },
    },
  },
  created () {
    if (this.renterEdit && this.renterEdit['@id']) {
      this.renter = this.renterEdit
      this.isEditingRenter = true
    }
  },
  methods: {
    ...mapActions('renter', {
      post: 'post',
      put: 'put',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.renter['@id']) {
          const renterUpdated = await this.put(this.renter)
          this.$emit('renterUpdated', renterUpdated)
        } else {
          this.$emit('renterCreated', await this.post(this.renter))
        }

        this.clear()
        this.$emit('close')
      } finally {
        this.isSubmiting = false
        this.isEditingRenter = false
      }
    },
    clear () {
      this.renter = newRenter()
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
  },
})
